$( document ).ready(function() {
  $('.flatpickr.datetime').flatpickr({
    altInput: true,
    altFormat: "F j, Y H:i",
    dateFormat: "Z",
    time_24hr: false,
    enableTime: true
  })

  $('.select2').select2({
    tags: true,
    tokenSeparators: [',']
  })
})