$(document).on('click', '[data-behavior="expand-talent-bio"]', function(event) {
  event.preventDefault()
  let button = $(this)
  let bioContainer = button.closest('.talent-bio')
  if (bioContainer.hasClass('expanded')) {
    button.html('<i class="fas fa-chevron-down mr-2"></i> Show more...')
    bioContainer.removeClass('expanded')
  } else {
    button.html('<i class="fas fa-chevron-up mr-2"></i> Show less...')
    bioContainer.addClass('expanded')
  }
})